import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { useEffect } from 'react';

import "./App.scss";
import Header from "./SHARE_MODULE/Header";
import HomePage from "./PAGES_MODULE/HomePage/HomePage";
import EastMeetsWestFindingYourOwnVoice from "./PAGES_MODULE/EastMeetsWestFindingYourOwnVoice/EastMeetsWestFindingYourOwnVoice";
import BodyImageServices from "./PAGES_MODULE/BodyImageServices/BodyImageServices";
import CouplesCounsellingServices from "./PAGES_MODULE/CouplesCounselling/CouplesCounsellingServices";
import Contact from "./PAGES_MODULE/Contact/Contact";

ReactGA.initialize("UA-226261386-2");

function App() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <main className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/east-meets-west-finding-your-own-voice" exact>
            <EastMeetsWestFindingYourOwnVoice />
          </Route>
          <Route path="/body-image-services" exact>
            <BodyImageServices />
          </Route>
          <Route path="/couples-counselling" exact>
            <CouplesCounsellingServices />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
        </Switch>
        <section className="pageLast">
          <div className="container">
            <div class="copy">
              <p>© Navneet Gill Counselling. All rights reserved. </p>
            </div>
          </div>
        </section>
      </Router>

      <div id="btt" onClick={() => scrollToTop()}>
        <i class="fa fa-angle-double-up"></i>
      </div>
    </main>
  );
}

export default App;
