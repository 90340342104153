export default function ContactWedged() {
    return (
        <>

            <section className="contactFormWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-auto mx-auto">
                            <div className="form">
                                <h2>CONTACT ME</h2>

                                <div className="form-group">
                                    <input type="text" name="" id="" placeholder="Name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="" id="" placeholder="Email" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="" id="" placeholder="Subject" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <textarea name="" id="" placeholder="Message" className="form-control"></textarea>
                                </div>

                                <div className="submit text-center">
                                    <a href="" class="btn btn-md btn-primary rounded-pill"><span>Submit</span></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
