import ContactWedged from "../../SHARE_MODULE/ContactWedged";
import GATracker from '../../SHARE_MODULE/GATracker';
import backgroundImage from "../../assets/img/Navneet-with-dog-landscape.jpg";

export default function Contact() {

  const tracker = GATracker('Contact Us');

  return (
    <>
      <section
        className="innerBanner bigInner"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <img src="/img/navneet-gill-headshot-smiling-2.jpg" alt="" /> */}
        <div className="content">
          <div className="container">
            <h1>CONTACT</h1>
          </div>
        </div>
      </section>

      <section className="contactInfoWrap">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-md-6 col-lg-auto">
              <div class="service-panel">
                <img
                  src="/img/navneet-blue-logo-callygraphy.png"
                  alt="Navneet Gill Logo Blue"
                />
                <div class="info-service">
                  <h6>Free Consultation</h6>
                  <p>
                    A 15-min phone call to discuss your needs and answer any
                    questions
                  </p>
                  <hr />
                  <div class="service-cta-info d-flex justify-content-between align-items-center">
                    <div>15 min</div>
                    <a
                      href="https://navneetgillcounselling.janeapp.com/#staff_member/1"
                      class=" btn-primary"
                    >
                      <span onClick={() => { tracker('click', 'Free Consultation') }}>Book Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-auto">
              <div class="service-panel">
                <img
                  src="/img/navneet-yellow-logo-callygraphy.png"
                  alt="Navneet Gill Logo Blue"
                />
                <div class="info-service">
                  <h6>Individual Session</h6>
                  <p>Video or phone only</p>
                  <hr />
                  <div class="service-cta-info d-flex justify-content-between align-items-center">
                    <div>
                      <span>1 hr</span>
                      <br />
                      <span>$175</span>
                    </div>
                    <a
                      href="https://navneetgillcounselling.janeapp.com/#staff_member/1"
                      class=" btn-primary"
                    >
                      <span onClick={() => { tracker('click', 'Individual Session Click') }}>Book Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-auto">
              <div class="service-panel">
                <img
                  src="/img/navneet-red-logo-callygraphy.png"
                  alt="Navneet Gill Logo Blue"
                />
                <div class="info-service">
                  <h6>Couples Session</h6>
                  <p>Video or phone only</p>
                  <hr />
                  <div class="service-cta-info d-flex justify-content-between align-items-center">
                    <div>
                      <span>75 min</span>
                      <br />
                      <span>$250</span>
                    </div>
                    <a
                      href="https://navneetgillcounselling.janeapp.com/#staff_member/1"
                      class=" btn-primary"
                    >
                      <span onClick={() => { tracker('click', 'Couples Session Click') }}>Book Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactWedged />
    </>
  );
}
