import ContactWedged from "../../SHARE_MODULE/ContactWedged";
import LetsConnect from "../../SHARE_MODULE/LetsConnect";
import backgroundImage from "../../assets/img/headshot-nav-1.jpg";
export default function CouplesCounsellingServices() {
  return (
    <>
      {window.scrollTo(0, 0)}
      <section
        className="innerBanner"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <img src="/img/navneet-gill-headshot-smiling-2.jpg" alt="" /> */}
        <div className="content">
          <div className="container">
            <h1>COUPLES COUNSELLING</h1>
          </div>
        </div>
      </section>

      <section className="body-image-services">
        <div className="container">
          <p>
            Marriage is hard work, and most relationships face difficulty at
            some point. Perhaps your relationship is in distress because of
            issues around money, kids, chores, sex, in-laws, or managing time.
            You and your partner are fighting more, feeling disconnected, and
            having a hard time finding the common ground you were on when you
            first got together <br /> <br />
            It’s hard to speak up or find time for yourself when it feels like
            everyone else’s needs must come first. You might find yourself
            playing the role of the peacekeeper or shutting down because you
            just don’t feel heard. You’re left feeling frustrated, lonely, and
            perhaps even rejected. You are at a crossroad now and feel torn.
            <br /> <br />
            <img src="/img/partners.jpg" alt="" />
            You feel burdened by the responsibility of managing the expectations
            of your partner, of the family, and perhaps even the community, but
            you also know that your needs are important too and you long to get
            back the happy and fulfilling relationship you once had. Sometimes,
            things feel really overwhelming and you may find yourself asking,
            “where do we even begin?”, or “how do we fix this?"
            <br /> <br />
            When you and your partner reach out to book a session, I will
            provide a safe environment for you both to be heard. We will work
            together on remembering what brought you together in the first place
            and understanding where the breakdown in the relationship started.
            <br /> <br />
            You can expect to:
            <br />
            <ul>
              <li>Explore your fears and insecurities</li>
              <li>Learn to share your feelings with your partner</li>
              <li>Build upon your strengths</li>
              <li>Become more understanding and accepting</li>
            </ul>
            <br />
            Rather than taking sides, my role is to be an advocate for the
            relationship. Session by session, you will learn to fight fair,
            improve communication, and rebuild trust. As your communication
            improves, you can expect to feel safer, be kinder to one another, be
            able to resolve conflicts more easily and experience greater
            emotional connection and intimacy.
          </p>
        </div>
      </section>
      <LetsConnect />

      <ContactWedged />
    </>
  );
}
