import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
export default function Header() {
  const [MobMenu, setMobMenu] = useState(false);
  const movMenuHandel = () => {
    setMobMenu(!MobMenu);
  };
  return (
    <>
      <header>
        <div className="layerOne">
          <div className="container">
            <div className="row justify-content-between align-items-center  ">
              <div className="col-auto">
                <Link to="/" className="logo">
                  <img src="/img/logo.svg" alt="" />
                </Link>
              </div>

              <div className="col-auto">
                <div class="phoneBlock">
                  <i class="flatIcon  Fphone2 flaticon-technology"></i>
                  <div>
                    <p>Call Me Now</p>
                    <h5 class="h5 as">
                      <a href="tel:+1-587-412-5184">1-587-412-5184</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-auto d-md-none">
                <button
                  className="btn shadow-none p-0 mobileNavBtn"
                  onClick={() => movMenuHandel()}
                >
                  <div
                    class={`menuIcon ${MobMenu === true && "menuIconActive"}`}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class={`main-nav ${MobMenu === true && "active"}`}>
          <div className="container">
            <nav class="">
              <ul
                className="list-unstyled d-md-flex"
                onClick={() => movMenuHandel()}
              >
                <li>
                  <NavLink to="/" activeClassName="active" exact>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/east-meets-west-finding-your-own-voice"
                    activeClassName="active"
                    exact
                  >
                    East meets West
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/body-image-services"
                    activeClassName="active"
                    exact
                  >
                    Body Image &amp; Self Esteem
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/couples-counselling"
                    activeClassName="active"
                    exact
                  >
                    Couples Counselling
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active" exact>
                    Contact
                  </NavLink>
                </li>
              </ul>{" "}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
