import backgroundImage from "../../assets/img/navneet-gill-headshot-smiling-2.jpg";
import ContactWedged from "../../SHARE_MODULE/ContactWedged";
import LetsConnect from "../../SHARE_MODULE/LetsConnect";

export default function EastMeetsWestFindingYourOwnVoice() {

    return (
        <>
        { window.scrollTo(0, 0)}
            <section className="innerBanner" style={{ backgroundImage: `url(${backgroundImage})` }}>
                {/* <img src="/img/navneet-gill-headshot-smiling-2.jpg" alt="" /> */}
                <div className="content">
                    <div className="container">
                        <h1>East meets West</h1>
                        <h2>FINDING YOUR OWN VOICE</h2>
                    </div>
                </div>
            </section>

            <section className="e-m-w-f-y-o-v-Wrap">
                <div className="container">
                    <p>
                        <img src="/img/Henna.jpg" alt="" />
                        Your parents grew up in a different country, but you’ve been brought up in western culture. As a result you’re left trying to navigate the culture of your family while trying to fit into life here in Canada. At home you have learned that young women take care of the family unit, do housework, and don’t question the ways of tradition. Yet you’re still expected to excel in academics, sports, and be the best in western society. This leaves you torn trying to figure out where you fit. How do you bridge this gap between the east and west?
                        <br /> <br />

                        You struggle to say no, and overextend yourself out of fear of disappointing others. You have taken on the role of people pleaser and are at a crossroad now. You desire to step outside of this role and push the boundaries but you are riddled with guilt and anxiety. Managing the happiness of others has taken its toll on you and now you find yourself asking “Hey, what about me?” … “When is it my turn?”

                        <br /> <br />

                        <img src="/img/skith-family-west-meets-east-bg-900.jpg" alt="" />

                        You are terrified at the thought of speaking up and asking for what you need. You are scared about letting others down and feel overwhelmed for questioning the way things have always been. Speaking your truth and finding your voice feels downright frightening. Not to mention the worry of being perceived as a “bad influence or a bad girl” in your family’s social circle because you have an opinion to share.

                        <br /> <br />

                        I’m here to tell you that once you get comfortable with uncertainty you will feel light and free. The burden of managing the happiness of others will be lifted and you will take the path of living your life that feels authentic to you. A journey of self discovery and healing begins when you learn to say yes to yourself and speak your mind.

                        <br />
                        <br />

                        We work together to focus on building your assertiveness and accepting yourself. You will be able to acknowledge what culture means to you and what parts of both cultures resonate with you. Let me be part of your self-acceptance journey and find your cultural identity.
                    </p>
                </div>
            </section>

            <LetsConnect />

            <ContactWedged />
        </>
    )
}
