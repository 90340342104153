import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import GATracker from '../../SHARE_MODULE/GATracker';
import ContactWedged from "../../SHARE_MODULE/ContactWedged";
import LetsConnect from "../../SHARE_MODULE/LetsConnect";
import bigImg from "../../assets/img/navneet-gill-intro-website-bg.jpg";
let fH = window.innerWidth / 10 - 20 + "px";

export default function HomePage() {
  const [dynamicHeight, setdynamicHeight] = useState(fH);
  // window.onload = function () {
  //     document.getElementById('heroCont').classList.add('expand');
  // };
  useEffect(() => {
    document.getElementById("heroCont").classList.add("expand");

    window.onresize = function (event) {
      setdynamicHeight(window.innerWidth / 10 - 20 + "px");
    };
  }, []);

  const tracker = GATracker('Home Page');

  return (
    <>
      <section
        className="heroSection"
        style={{ backgroundImage: `url('${bigImg}')` }}
      >
        {/* <img src="/img/img1.jpg" alt="" /> */}
        <div className="cont" id="heroCont">
          <h1>
            Welcome to my practice, <br />
            I'm honored you're here.
          </h1>
          <div className="btn-Wrap">
            <a
              href="https://navneetgillcounselling.janeapp.com/#staff_member/1"
              className="btn btn-md btn-primary rounded-pill"
            >
              <span onClick={() => { tracker('click', 'Book Now') }}>Book Now</span>
            </a>
          </div>
        </div>
      </section>

      <section className="homeLayer2">
        <div className="container">
          <div className="row">
            <div className="col-md-auto mx-auto">
              <div className="mainCont">
                <p class="has-text-align-center has-normal-font-size">
                  <strong>
                    Sharing your story can feel like a daunting task.
                  </strong>
                  <br />
                  <br />
                  It’s terrifying to be so vulnerable. So let me put you at ease
                  by sharing first. My parents immigrated to Canada when I was 3
                  months old. I grew up as a second generation Canadian. Growing
                  up I constantly faced challenges of trying to balance my
                  Punjabi culture and traditions while trying to fit into
                  western society. Growing up in a collectivist culture, I
                  learned not to question the way and rules of the elders. I
                  learned from a young age that the needs of others were always
                  a priority and my voice did not matter. I quickly adopted the
                  role of people pleasing and seeking the approval of others. So
                  began my journey of self-loathing, poor self esteem, and low
                  confidence. Several decades later, after becoming a parent, I
                  hit my breaking point. After years of being told who I was,
                  how I should look, how I should behave, what I should feel… I
                  knew that I didn’t want my son to go through the same thing. I
                  wanted him to have the chance to know who he was, to have a
                  voice, to discover his own path, and that was the day I said,
                  “Enough.”
                  <br />
                  <br />
                  From that day onward, I embarked on my Masters degree in
                  Counselling Psychology, began attending counselling myself and
                  engaged in personal development work to learn how to set and
                  enforce boundaries. I found my voice and learned how to say
                  “no”, and most importantly, I learned how to put myself first.
                  I won’t pretend that this transformation happened overnight. I
                  had also struggled with my weight and body image since I was a
                  child. My journey to healing was full of guilt, anxiety, and
                  discomfort. To date, facing my past and healing the wounds I
                  had incurred is the hardest thing I have done. The biggest
                  lesson I learned along the way is that in order to truly say
                  “yes” to myself, I needed to learn to say “no” to others. And
                  not only was that ok, but it was necessary.
                  <br />
                  <br />
                  As a result, I was finally able to shed the extra weight that
                  I had carried with me – both literally and figuratively. I
                  learned to take care of myself, to put myself first and how to
                  meet my own needs. For the first time, I began to feel
                  confident in my own skin. By letting go of carrying the burden
                  of responsibility of caring for others, I felt a huge sense of
                  relief. For the first time in my life, I experienced what it
                  was like to truly love myself and feel worthy. I finally knew
                  that I mattered, that my thoughts and feelings counted for
                  something, and that my wants, needs and desires were important
                  too. Once I let go of the guilt and unworthiness, I was no
                  longer stuck. I was free to make my own choices and create a
                  life that felt authentic to me. The doors of possibility flung
                  wide open and now I am able to enjoy feelings of self-love,
                  self-respect, happiness, hope and a renewed sense of energy.
                  This kind of change is possible for you too, and I’m truly
                  looking forward to supporting you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homeLayer3">
        <div className="container">
          <p class="prof-bio">
            <strong>Professional Bio</strong>
            <br />
            <br />
            <img
              loading="lazy"
              width="450"
              height="450"
              class="wp-image-212"
              style={{ width: " 450px" }}
              src="/img/navneet-gill-smiling-headshot-2.jpg"
              alt="Navneet Gill"
            />
            I am a member of the Canadian Counselling and Psychotherapy
            Association (CCPA) and a Licensed Occupational Therapist (OT)
            with the Alberta College of Occupational Therapists (ACOT). I hold a
            Bachelor of Occupational Therapy with distinction, which I obtained
            from the University of Alberta in 1999. Most recently, I completed
            my Master’s degree in counselling psychology through Yorkville
            University in 2021 and obtained my life coaching certification
            through Coach Training Alliance in 2015.
            <br />
            <br />
            Before embarking on private practice, I worked as an occupational
            therapist in home care for over 20 years. My professional focus was
            on helping clients remain in their home and live in the community.
            In my private practice I support women experiencing anxiety, stress,
            and relationship difficulties. I have recently completed the
            Trauma-Informed Care certification and the 5-day Foundations of
            Narrative Therapy Certification through the Vancouver School for
            Narrative Therapy.
            <br />
            <br />I am a wife, mom, daughter, sister, dog mom, and friend. I
            enjoy reading, cooking, travelling and being outdoors. I love
            learning and growing. Those who know me, know that my willingness to
            help others is what brings me the most joy and fulfillment.
          </p>
        </div>
      </section>

      <section className="homeLayer4">
        <div className="container">
          <h2>My Services</h2>
          <div className="row">
            {/* east meets west */}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <Link
                to="/east-meets-west-finding-your-own-voice"
                className="serviceCard"
              >
                <img
                  src="/img/skith-family-west-meets-east-bg-800.jpg"
                  alt=""
                />
                <div className="cont red">
                  <p class="has-text-align-center has-large-font-size">
                    East meets west
                    <br />
                    <br />
                    <i>
                      This individual counselling service is right for you if
                      you’ve been trying to navigate two worlds. You struggle to
                      meet your parents’ traditional expectations while also
                      living life on your own terms.
                    </i>
                    <br />
                    <br />
                    <span onClick={() => { tracker('click', 'Learn More East Meets West') }}>Learn More</span>
                  </p>
                </div>
              </Link>
            </div>

            {/* body image and self esteem */}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <Link to="/body-image-services" className="serviceCard">
                <img src="/img/self-esteem-body-image-services-2.jpg" alt="" />
                <div className="cont blue">
                  <p class="has-text-align-center has-large-font-size">
                    Body image &amp; <br />
                    Self-esteem
                    <br />
                    <br />
                    <i>
                      This individual counselling service is right for you if
                      you are struggling to love your body and yourself. You want
                      to feel confident in your own skin and learn to love
                      yourself unconditionally.
                    </i>
                    <br />
                    <br />
                    <span onClick={() => { tracker('click', 'Learn More Body Image') }}>Learn More</span>
                  </p>
                </div>
              </Link>
            </div>

            {/* couples therapy */}
            <div className="col-lg-4">
              <Link to="/couples-counselling" className="serviceCard">
                <img src="/img/partners.jpg" alt="" />
                <div className="cont red">
                  <p class="has-text-align-center has-large-font-size">
                    Couples Therapy
                    <br />
                    <br />
                    <i>
                      This counselling service is right for you if you've been
                      struggling in your relationship or marriage. You struggle
                      with effective communication, understanding your partner,
                      fighting fair, or being supportive when needed.
                    </i>
                    <br />
                    <br />
                    <span onClick={() => { tracker('click', 'Learn More Couples Therapy') }}>Learn More</span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <LetsConnect />

      <ContactWedged />
    </>
  );
}
