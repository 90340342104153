import GATracker from '../SHARE_MODULE/GATracker';

export default function LetsConnect() {

    const tracker = GATracker("Let's Connect");

  return (
    <>
      <section className="homeLayer5">
        <div className="d-flex">
          <div class="contact-info-left d-flex align-items-center">
            <div className="inner d-flex  flex-column justify-content-around">
              <div>
                Virtual online services across Canada (exceptions include the
                provinces of Ontario, Quebec, Nova Scotia, New Brunswick and
                PEI).
              </div>
              <hr />
              <div>
                <div>
                  <i class="fa fa-phone"></i>{" "}
                  <a href="tel:+1-587-412-5184" onClick={() => { tracker('click', 'Phone Number') }}>1-587-412-5184</a>
                </div>
                <div>
                  <i class="fa fa-envelope-o"></i>{" "}
                  <a href="mailto:contact@navneetgillcounselling.com" onClick={() => { tracker('click', 'Email') }}>
                    contact@navneetgillcounselling.com
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-form-right ">
            <div className="d-flex flex-column justify-content-center h-100">
              <h3>
                Let’s <span class="red-letters">connect</span>
              </h3>
              <p>
                Have questions about services before booking? Contact me to
                arrange a free 15-minute consultation to see if this is a good
                fit for you.
              </p>
              <div className="mt-3">
                <a
                  href="https://navneetgillcounselling.janeapp.com/#staff_member/1"
                  class="btn btn-md btn-primary rounded-pill"
                >
                  <span onClick={() => { tracker('click', 'Book Now') }}>Book Now</span>
                </a>
              </div>
            </div>
          </div>

          <div class="photo-right-contact">
            <img src="/img/navneet-and-dog.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
}
