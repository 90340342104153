import ContactWedged from "../../SHARE_MODULE/ContactWedged";
import LetsConnect from "../../SHARE_MODULE/LetsConnect";
import backgroundImage from "../../assets/img/navneet-headshot-dark.jpg";
export default function BodyImageServices() {
    return (
        <>
         { window.scrollTo(0, 0)}
            <section className="innerBanner" style={{ backgroundImage: `url(${backgroundImage})` }}>
                {/* <img src="/img/navneet-gill-headshot-smiling-2.jpg" alt="" /> */}
                <div className="content">
                    <div className="container">
                        <h1>BODY IMAGE & SELF-ESTEEM</h1>
                    </div>
                </div>
            </section>


            <section className="body-image-services">
                <div className="container">
                    <p>
                        Every time you pass a mirror, the voice in your head screams your insecurities. “I’m ugly, I’m so fat, or my arms are flabby.” As if the self loathing wasn’t enough now the comparisons have creeped in. “She can pull off that outfit better than you, why don’t you look like the gal everyone is following on Instagram?” <br /> <br />

                        You fight so hard to turn off the self-criticisms but thoughts of “I’m not good enough”, still echo in your head. You are constantly searching for validation and approval from others to fill the void of not measuring up. The dissatisfaction with your appearance infiltrates your confidence and self doubt kicks in. Relationships and friendships feel burdensome because you never feel ok with who you are. Your productivity and work are suffering because you constantly second guess your decisions and actions. You struggle to love yourself and be ok with who you are.

                        <br /> <br />

                        <img src="/img/self-love-bw.jpg" alt="" />
                        Change can be scary so sometimes it is easier to stick with what’s familiar and comfortable, especially if we’ve lost hope that change is possible. I’m here to tell you that change is possible. It’s not always easy, but you can learn to love your body, develop a positive and loving relationship with yourself. Imagine how it would feel to break the cycle of criticizing and disapproving of yourself! Let me tell you it’s freeing and refreshing.

                        <br /> <br />

                        When we meet you will feel supported, safe and met with no judgement. What you have to say and share matters. You will learn to challenge the negative self-talk and reframe it to be more positive. You will learn to love you, just the way you are, and practice self compassion. As you begin to understand the stories you have told yourself you will shed light on why you see yourself and the world the way you do. This will help you to foster change as you embrace self love.
                        <br /> <br />

                        We work together to focus on building healthy behaviours and kindness towards your relationship with your body. The focus shifts from flaws towards strengths and true beauty. Say goodbye to self sabotage because you have a choice, and you are in control of how the rest of your life is going to play out. Let me join you on this journey.
                    </p>
                </div>
            </section>
            <LetsConnect />

            <ContactWedged />
        </>
    )
}
